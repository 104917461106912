@import url("https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap");

html {
	font-size: 2.2vh;
}
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

/* ====== PAGE ==== */
main {
	height: 100vh;
	height: 100svh;
	width: 100vw;
	position: fixed;
	background: black;
	color: white;
	font-family: "Fugaz One", sans-serif;
	text-transform: uppercase;
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
	touch-action: none;
}
.main-inner {
	height: 100vh;
	width: 100vw;
	position: relative;
}
.gameOrigin {
	position: absolute;
	top: 50vh;
	/* left: 75vw; */
	left: 25vw;
	/* outline: 3px solid yellow; */
}

/* ====== BALL AND COURT  ==== */

.court {
	overflow: hidden;
	pointer-events: none;
}
.court-group-for-scaling {
	position: absolute;
	transition: scale 0.3s ease;
}
.court-group-for-scaling > * {
	position: absolute;
	/* translate: -50% -50%; */
}
.lights,
.barriers,
img.court-floor-fixed,
img.court-lines {
	translate: -50% -50%;
}
.lights {
	position: relative;
}
.lights .spot {
	position: absolute;
	width: 10vh;
	height: 60vh;
	top: -40vh;
	translate: 50% -5%;

	/* background-color: #ffffff33; */
	border-radius: 50%;
	filter: blur(1.5vh);
}
.spot1 {
	left: 30vh;
	rotate: 10deg;
}
.spot2 {
	left: 11vh;
	rotate: 20deg;
}
.spot3 {
	right: 11vh;
	rotate: -20deg;
}
.spot4 {
	right: 30vh;
	rotate: -10deg;
}
.innerSpot1,
.innerSpot2,
.innerSpot3 {
	background-color: #ffffff22;
	border-radius: 50%;
	position: absolute;
	translate: -50% -5%;
}
.innerSpot1 {
	width: 100%;
	height: 100%;
}
.innerSpot2 {
	width: 50%;
	height: 50%;
}
.innerSpot3 {
	width: 20%;
	height: 20%;
}

.court-group-for-scaling .barriers {
	background-repeat: repeat-x;
	background-color: #333;
	height: 9vh;
	width: 250vw;
	background-size: contain;
	top: 12vh;
	box-shadow: inset 0 -2vh 2vh 2vh #00000066;
	filter: blur(2px);
}
.realBall {
	pointer-events: initial;
}
.court > img {
	position: absolute;
	/* -webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%); */
	translate: -50% -50%;
	height: 100vh;
}
.court img.court-floor-fixed {
	margin-top: 18vh;
}
.court img.court-lines {
	margin-top: 4vh;
	/* transition: scale 0.3s ease; */
}
.court img.background-logo {
	/* margin-top: -15vh; */
	margin-top: 8vh;
	width: 42vmin;
	height: auto;
	opacity: 0.4;
}
.court:not(#ballHolder) {
	/* pointer-events: none; */
}
.hoop {
	position: absolute;
	transition: all 0.3s ease;
}
.court img.hoop-back {
	position: absolute;
	/* translate: -50% -50%; */
	translate: -50% 0;
	/* -webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%); */
	height: 20vh;
	/* bottom: 15vh; */
	/* outline: 1px dotted pink */
}
.court img.hoop-front {
	position: absolute;
	/* translate: -50% -50%; */
	/* -webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%); */
	height: 10vh;
	bottom: 15.2vh;
	/* z-index: initial; */
	/* outline: 1px dotted pink */
	/* scale: 2; */
}
.hoop-front-holder img.hoop-front,
.hoop-front-holder img.hoop-back {
	/* outline: 1px dotted greenyellow */
}

#ballHolder {
	position: absolute;
	transition: left 0.7s ease-out, bottom 0.7s cubic-bezier(0.1, 1.1, 0.59, 1.78);
	overflow: visible;
	translate: 0 0;
	/* -webkit-transform: translate(0 0);
	transform: translate(0 0); */
}
.phase_gettingBallReady #ballHolder {
	transition: left 0.4s ease-out, bottom 0.4s ease-in /* cubic-bezier(0.1, 1.1, 0.59, 1.78) */;
}

.Ball {
	width: 15vh;
	height: 15vh;
	border-radius: 50%;
	background-color: orangered;
	translate: -50% 50%;
	/* translate: -50% 0; */
	/* -webkit-transform: translate(-50%, 50%);
	transform: translate(-50%, 50%); */
	transition: scale 0.6s ease-out;
	/* transition: scale ease-out; */
	position: relative;
	overflow: hidden;
	/* experimental */
	background: radial-gradient(62.91% 62.91% at 50% 72.68%, #751c00 14.58%, #b72100 51.04%, #ff7b00 100%);
}
.phase_hasHit #ballHolder {
	padding-bottom: 1rem;
	transition: left 0.1s ease-out, bottom 0.8s cubic-bezier(0.96, 0, 1, 0.85),
		padding-bottom 0.6s cubic-bezier(0.1, 7, 0.9, 4) 0.8s;
}

.BallHighlight {
	position: absolute;
	top: 20%;
	width: 100px;
	height: 100px;
	background-color: darkorange;
	outline: 2px solid white;
}
.ballLine1,
.ballLine2,
.ballLine3,
.ballHighlight,
.ballGlow,
.pullMe_text {
	position: absolute;
	border-radius: 50%;
}
.ballLine1 {
	left: -40%;
	width: 80%;
	height: 80%;
	border: 2px solid brown;
}
.ballLine2 {
	left: -55%;
	top: -10%;
	width: 120%;
	height: 120%;
	border: 2px solid brown;
}
.ballLine3 {
	left: -70%;
	top: -15%;
	width: 160%;
	height: 160%;
	border: 2px solid brown;
}
.ballGlow {
	left: 0%;
	top: 0%;
	width: 100%;
	height: 100%;
	border: 1vh solid #ffff0000;
}
.ballHighlight {
	left: -10%;
	top: -15%;
	width: 100%;
	height: 100%;
	background-color: darkorange;
	/* experimental */
	display: none;
}
.pullMe_text {
	display: none;
}
.phase_readyToShoot .pullMe_text {
	display: block;
	top: 50%;
	left: 50%;
	text-align: center;
	transform: translate(-50%, -50%);
}
#target {
	position: absolute;
	border: 5px solid transparent;
	translate: -50% 0;

	/* -webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0); */
}

.words {
	position: absolute;
	/* left: 75vw; */
	top: -4vh;
	color: currentColor;
	/* z-index: 999; */
}
.bigWords,
.smallWords,
.shotPower {
	text-align: center;
	line-height: 100%;
	translate: -50% 0;
	/* -webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0); */
	width: 45vw;
}
.bigWords {
	font-size: 4rem;
}
.smallWords {
	font-size: 1.5rem;
	color: #f9ca00;
}
.shotPower {
	font-size: 1.5rem;
	/* color: #f9ca00; */
}
.smallWords:nth-child(3) {
	opacity: 0.7;
}
.targetText {
	position: absolute;
	bottom: 140%;
	width: 100%;
	text-align: center;
	font-size: 1.7rem;
}
.scoreboard {
	position: absolute;
	width: 40vw;
	height: 10vh;
	padding-top: 2vh;
	left: -20vw;
	bottom: -48vh;
	background: linear-gradient(180deg, #fad820 0%, #f9d910 51.55%, #f9ca00 51.56%, #f9cc00 88.02%, #f6b400 100%);
	box-shadow: 0px -2px 2px #ffffff66, 0px 1px 2px 16px rgba(0, 0, 0, 0.25);
	border-radius: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
	line-height: 100%;
	color: black;
	gap: 0.4rem;
}
.points {
	text-transform: initial;
	font-size: 2rem;
	/* padding-left: .3rem */
}
.basketStreak {
	color: red;
}
.shotsRemaining {
	display: flex;
	gap: 0.8rem;
	/* scale: 0.3; */
	flex-direction: row;
	position: absolute;
	top: -3vh;
}
.shotsRemaining .Ball {
	/* scale: 0.3; */
	translate: none;
	/* -webkit-transform: none;
	transform: none; */
	width: 5vh;
	height: 5vh;
}
.shotsRemaining,
.point,
.basketsScored {
	width: min-content;
}
.difficulty-select {
	position: absolute;
	z-index: 9999999;
	left: 0;
	top: 0;
	height: min-content;
	font-size: 2vh;
	background-color: #b7210033;
	padding: 2vh;
	display: flex;
	flex-direction: row;
	line-height: 100%;
	justify-content: center;
	gap: 2vh;
	pointer-events: all;
	color: white;
	font-family: "Fugaz One", sans-serif;
	text-transform: uppercase;
	transition: all 0.8s;
}
.difficulty-select .logo {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	width: 50vw;
	height: 15vh;
}
.difficulty-select .small {
	font-size: 2vh;
}
.showDifficultyAsModal {
	width: 100vw;
	height: 100vh;
	flex-direction: column;
	align-items: center;
	background-color: #b72100ee;
	font-size: 6vh;
	gap: 2vh;
}
.showDifficultyAsModal .difficulty-option {
	display: flex;
	flex-direction: column;
	gap: 1vh;
	text-align: center;
	line-height: 100%;
	background-color: #00000066;
	width: 35vh;
	padding: 3vh;
	border-radius: 2vh;
}
.difficulty-select:not(.showDifficultyAsModal) .comment {
	display: none;
}

.difficulty-option .comment {
	font-size: 2vh;
	line-height: 1vh;
}
.difficulty-option.selected {
	color: yellow;
	text-shadow: 2px 2px red;
}

.ballEndPoint {
	position: absolute;
	border-radius: 50%;
	border: 0.5vmin solid #ff000099;
	/* background-color: pink; */
	width: 2vmin;
	height: 2vmin;
	opacity: 1;
	transition-property: opacity;
	transition-duration: 0.2s;
	transition-delay: 0.7s;
}
.phase_readyToShoot .ballEndPoint,
.phase_waitingForNextCorrectQuizAnswer .ballEndPoint,
.phase_hasHit .ballEndPoint {
	opacity: 0;
}
.ballGhostEndPoint {
	position: absolute;
	/* outline: 5px solid rgb(0, 217, 255); */
}
.hidden {
	visibility: hidden;
}
.elasticLine {
	/* outline: 1px dotted yellow; */
	position: absolute;
}
.phase_readyToShoot .realBall {
	opacity: 0;
}
/* .upToTheLeft .Ball,
.upToTheRight .Ball, */
.pulledBall {
	position: absolute;
	bottom: 0;
	opacity: 1;
	/* visibility: hidden; */
}
.upToTheLeft .Ball {
	right: 0;
	translate: 50% 50%;
	/* -webkit-transform: translate(50%, 50%);
	transform: translate(50%, 50%); */
}
.upToTheRight .Ball {
	left: 0;
}

svg:not(:root) {
	overflow: visible;
}
svg #arrowhead {
	scale: 50%;
}
.shotPower {
	display: none;
}
.phase_readyToShoot .shotPower {
	display: block;
}
.ballShadow {
	width: 9vw;
	height: 4vh;
	background: #00000033;
	/* outline: 1px solid red; */
	translate: -50% -50%;
	top: 51vh;
	border-radius: 50%;
	opacity: 0;
	transition: all 0.6s ease;
}
.phase_duringShot .ballShadow,
.phase_hasHit .ballShadow,
.phase_hasMissed .ballShadow {
	/* outline: 1px solid greenyellow; */
	top: 22vh;
	opacity: 1;
}

/* ======== GAMEPLAY  ======= */

.phase_waitingForNextCorrectQuizAnswer #ballHolder,
.phase_waitingForNextCorrectQuizAnswer .elasticLine {
	visibility: hidden;
}

.phase_readyToShoot #ballHolder,
.phase_readyToShoot #elasticLine {
	visibility: visible;
}
.phase_readyToShoot #ballHolder {
	/* transition: left 0.6s ease-out, bottom 0.6s cubic-bezier(0.1, 1.1, 0.59, 1.78); */
}
.phase_readyToShoot .ballGlow,
.phase_readyToShoot .ballGlow {
	animation: glowing 1s alternate infinite;
}
.phase_readyToShoot .scoreboard .ballGlow {
	animation: none;
}
@keyframes glowing {
	to {
		border: 1vh solid #ffff00ff;
	}
}

.phase_duringShot .elasticLine {
	visibility: hidden;
}

.phase_hasHit #ballHolder,
.phase_hasMissed #ballHolder {
	/* transition-duration: 0; */
}
.phase_hasHit .elasticLine,
.phase_hasMissed .elasticLine {
	visibility: hidden;
}
.phase_hasHit {
	color: greenyellow;
}
.hoop-front-holder {
	opacity: 0;
	position: absolute;
	transition: scale 0.3s ease;
}
.phase_hasHit .hoop-front-holder {
	opacity: 1;
}
.phase_hasMissed {
	color: orange;
}
.phase_hasHit #target,
.phase_hasMissed #target {
	/* border: 5px solid currentColor;
  transition: border-color 0.2s ease 0.2s; */
}
.phase_gettingBallReady .elasticLine {
	visibility: hidden;
}

/* ======= endOfQuizOptions ======== */
.endOfQuizOptions {
	position: absolute;
	top: 7rem;
	right: 1rem;
	/* right: 1rem; */
	border-radius: 1rem;
	width: 50vw;
	background-color: white;
	/* min-height: 70vh; */
	z-index: 99999;
	display: grid;
	place-items: center;
	gap: 2rem;
	color: orangered;
	font-family: "Fugaz One", sans-serif;
	padding: 6rem 2rem;
	/* hide until phase_endOfGame */
	/* display: none; */
}
.endOfQuizOptions h2 {
	text-transform: uppercase;
}
/* .phase_endOfGame .endOfQuizOptions{
	display: grid;
}*/
.endOfQuizButton {
	padding: 1rem 2rem;
	background-color: orangered;
	color: white;
	border-radius: 5rem;
	transition: background-color 0.3s ease;
}
.endOfQuizButton:hover,
.endOfQuizButton.playAgain:hover {
	background-color: purple;
}
.endOfQuizButton a {
	text-decoration: none;
	color: white;
}
.endOfQuizButton.playAgain,
.endOfQuizButton.checkYourResults {
	background-color: orangered;
}

.faint {
	opacity: 0.4;
}
.pulsing {
	animation: pulsing 1s ease alternate infinite;
}
@keyframes pulsing {
	to {
		scale: 1.2;
	}
}

/* ======= quizplayer ======== */

.quizplayer {
	position: absolute;
	top: 0;
	/* left: 1rem; */
	right: 1rem;
	overflow: hidden;
	width: 50vw;
	max-height: 100vh;
	height: 100%;
}
.quizplayerOverlayMask {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
}
.maskA {
	position: absolute;
	height: 599px;
	background-color: white;
	border: 9px solid red;
	left: 80px;
	width: 710px;
	top: 103px;
}
.maskB {
	position: absolute;
	height: 304px;
	background-color: white;
	border: 9px solid red;
	left: 50%;
	top: 616px;
	width: 447px;
}
.maskTextA {
	position: absolute;
	left: 200px;
	top: 300px;
}

/* ======= testButtons ======== */
.testButtons {
	display: none;
	position: absolute;
	top: 1rem;
	right: 1rem;
}

/* ======= mobile overlay ======== */
.mobileOverlay {
	display: none;
}

@media only screen /* and (max-device-width: 800px) */ and (max-width: 800px) and (orientation: portrait) {
	.mobileOverlay {
		position: absolute;
		background-color: #000000dd;
		color: white;
		width: 90vw;
		min-height: 90vh;
		left: 5vw;
		top: 5vw;
		border-radius: 2vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1vh;
		padding: 5vw;
		font-size: 5vw;
	}

	.quizplayer {
		left: -100vw;
		transition: left 0.3s ease;
		width: 96vw;
	}
	.gameOrigin {
		left: 50vw;
	}
	.scoreboard {
		width: 96vw;
		left: -48vw;
		font-size: 4vh;
	}
	.shotsRemaining {
		gap: 1vw;
	}

	.phase_waitingForNextCorrectQuizAnswer .quizplayer {
		left: 2vw;
	}
}

/* ======= progressFlags and testLog ======== */

.progressFlags,
.testLog {
	position: absolute;
	width: 3px;
	height: 3px;
	top: 0;
	right: 0;
	overflow: hidden;
	color: grey;
	font-size: 10px;
}
.progressFlags:hover,
.testLog:hover {
	width: min-content;
	height: min-content;
}
.progressFlag {
}
.dev {
	/* outline: 3px dotted greenyellow; */
}
.dev2 {
	/* outline: 6px dotted pink; */
}

.testLog {
	display: none;
	left: 0;
}
